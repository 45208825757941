<div class="jumbo-greeting card bg-dark">
  <div class="card-img-overlay-color"></div>
  <img class="card-img" draggable="false" *ngIf="bannerImage"
        [src]="bannerImage.asset_url && bannerImageTransformations ? bannerImage.base_url + bannerImageTransformations + bannerImage.asset_url : bannerImage.file_url"
        [alt]="bannerImage?.description ? bannerImage.description : 'A background image suggesting wisdom'">
  <!-- Active if no image -->
  <!-- <div class="card-img-placeholder-color"></div> -->
  <div class="card-img-overlay">
    <div class="container">
      <div class="jumbo-content" *transloco="let t">
        <div class="content">
          <p class="card-text title">{{ titleTranslationKey ? t(titleTranslationKey) : titleText }}</p>
          <p class="card-text subtitle mt-4">{{ subtitleTranslationKey ? t(subtitleTranslationKey) : subtitleText }}</p>
        </div>
        <div class="img-holder">
            <img class="jumbo-content-img" *ngIf="featureImage"
        [src]="featureImage.asset_url && featureImageTransformations ? featureImage.base_url + featureImageTransformations + featureImage.asset_url : featureImage.file_url"
        [alt]="featureImage?.description ? featureImage.description : 'A background image suggesting wisdom'">
          <!-- <img class="jumbo-content-img" src="https://images.unsplash.com/photo-1590086782957-93c06ef21604?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=687&q=80" alt="Card image"> -->
        </div>
      </div>
    </div>
    
  </div>
</div>

