import { Component, Input, OnInit } from '@angular/core';
import { CloudinaryMediaAsset } from '../../../models';

@Component({
  selector: 'multisite-jumbo-greeting',
  templateUrl: './jumbo-greeting.component.html',
  styleUrls: ['./jumbo-greeting.component.scss']
})
export class JumboGreetingComponent implements OnInit {

  @Input() bannerImage: CloudinaryMediaAsset;
  @Input() featureImage: CloudinaryMediaAsset;
  @Input() bannerImageTransformations: string;
  @Input() featureImageTransformations: string;
  @Input() titleTranslationKey: string; // 'quiz.test_your_knowledge'
  @Input() titleText: string; // 'Test your knowledge'
  @Input() subtitleTranslationKey: string; // 'quiz.befoe_real_life_tests'
  @Input() subtitleText: string; // 'Before your international life tests it for real'

  constructor() { }

  ngOnInit(): void {
  }

}
