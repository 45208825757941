import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'multisite-access-code-user-input',
  templateUrl: './access-code-user-input.component.html',
  styleUrls: ['./access-code-user-input.component.scss']
})
export class AccessCodeUserInputComponent implements OnInit {

  @Input() loading: boolean;
  @Input() eventCodeError : string;
  @Output() eventCodeSubmitted = new EventEmitter<string>();

  constructor() { }

  submitEventCode(code:string){
    this.eventCodeSubmitted.emit(code)
   }

  ngOnInit(): void {
  }

}
