import { AfterViewInit, Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import * as L from 'leaflet';
import { CultureClusterWrapper } from '../../../clusters/culture-cluster-wrapper.model';
import { MediaService } from '../../../content';
L.Icon.Default.imagePath = 'assets/images/maps/'; // This line is an alternative to putting the following into the assets array in angular.json (which copies files from node_modules into the app). You can also place this line with the L import line into main.ts
// {
//  "glob": "**/*",
//  "input": "./node_modules/leaflet/dist/images/",
//  "output": "./assets/images/maps/"
// }
import { GeoJsonFeatureCollection } from '../../../maps/geojson-feature-collection.model';
import { GeoJsonFeature } from '../../../maps/geojson-feature.model';
import { Culture } from '../../culture.model';

type StyleOptions = {default : string, active : string};
type StyleOptionsSet = {fill : StyleOptions, border : StyleOptions, dashArray: StyleOptions};

type StatusStyles = { 
  selected : StyleOptionsSet,
  available : StyleOptionsSet,
  unavailable: StyleOptionsSet,
}

@Component({
  selector: 'culture-selector-type-two',
  templateUrl: './culture-selector-type-two.component.html',
  styleUrls: ['./culture-selector-type-two.component.scss']
})
export class CultureSelectorTypeTwoComponent implements OnChanges, AfterViewInit {

  @Input() clusters : CultureClusterWrapper[];
  selectedCluster : CultureClusterWrapper;
  selectedClusterId : number = null;
  @Input() features : GeoJsonFeature[];
  @Input() featureCollectionName : string; // Asian cultures
  @Input() selectedCultureIsos : string[] = []; // ['FR','DE']
  @Input() disabled: boolean;
  @Input() maxZoom: number = 5;
  @Input() minZoom: number = 1;
  @Input() maxBounds: L.LatLngBoundsExpression = [
    [-90, -200], // South West (-180 would fix the map to exactly one world with no duplication)
    [90, 235]    // North East
  ]; // Use this to prevent the user scrolling infinitely east and west (our features will only be added to the initial view, not endless views outside of the map canvas)
  @Input() displayFarWesternFeaturesInFarEast : boolean = true; // When true, islands/territories (Features) with the property 'displayOverDateLineInFarEast' will be displayed over the far east edge of the map, not the far west. For example Samoa or Cook Islands. 
  @Input() loading: boolean;
  @Input() allowUnavailableCulturesToBeSelected: boolean;
  @Input() hideUnavailableCulturesFromList: boolean;
  @Output() selectedCulture = new EventEmitter<Culture>(); // 'FR'
  view: string = 'map'; // or list 'list'
  featureCollection : GeoJsonFeatureCollection;
  private featureLayerMap: Map<string, any> = new Map<string, any>(); // Create a map to store references to feature layers so we can more eaily target layers with code
  featureAvailableMap: Map<string, boolean> = new Map<string, boolean>(); // Create a map to store references to the culture's availability
  private map!: L.Map
  // markers: L.Marker[];
  viewInitialised : boolean; // TODO - there must be a better way than this
  cloudinary_base_url : string; // 'https://res.cloudinary.com/cebt/image/upload/'
  statusStyles : StatusStyles;
  @Input() titleTranslationKey : string; // 'common.choose_culture'
  @Input() titleText : string; // 'Choose a culture'
  @Input() descriptionTranslationKey : string; // 'common.instruction_choosing_culture'
  @Input() descriptionText : string; // 'Choose a relevant culture'
  @Input() preambleTranslationKey : string; // 'survey.find_quiz'
  @Input() preambleText : string; // 'Find your cultural quiz'
  //@Input() limitToDisplayFarWesternFeaturesInFarEast : number = -160; // islands/territories (Features) with any coordinate at this longitude or lower will be displayed over the far east edge of the map, not the far west. For example Samoa or Cook Islands. If the feature also has the 
 
  constructor(private mediaService : MediaService) { 
    this.cloudinary_base_url = this.mediaService.cloudinary_base_url;

    // this.markers = [
    //   L.marker([45.267136, 19.833549]), // Novi Sad
    //   L.marker([60.169857, 24.938379]) // Helsinki
    // ];

    this.style = this.style.bind(this);
    this.onEachFeature = this.onEachFeature.bind(this);
    this.logCenterAndZoom = this.logCenterAndZoom.bind(this); // for dev
    this.statusStyles = {
      selected : {
        fill: {default: '#0CC770', active: '#12653e'},
        border :  {default: '#f2f2f2', active: '#f2f2f2'},
        dashArray :  {default: '', active: '3'}
      },
      available : {
        fill: {default: '#198754', active: '#139A5B'},
        border :  {default: '#f2f2f2', active: '#f2f2f2'},
        dashArray :  {default: '', active: '3'},
      },
      unavailable : {
        fill: {default: '#ced4da', active: '#adb5bd'},
        border :  {default: '#f2f2f2', active: 'transparent'},
        dashArray :  {default: '', active: '3'}
      },
    }
  }

  // Use https://colorbrewer2.org to get good colours for maps
  // Use https://openmaptiles.org/styles/ to get good tiles

  private style(feature) { // Default styles
    return {
        // fillColor: this.getColour(feature.properties.variable),
        fillColor: this.getStyle(feature.properties, 'fill' ,false), // or RGBA format : rgba(255, 0, 0, 0.5)
        weight: 1,
        opacity: 1,
        color: this.getStyle(feature.properties, 'border' ,false),
        dashArray: this.getStyle(feature.properties, 'dashArray' ,false),
        fillOpacity: 1 // if not using RGBA
    };
  }
  private styleActive(layer) {
    layer.options.interactive = false;
    layer.bringToFront();
    // We decided not to use CSS classes
    // const path = layer._path;
    // if (path){
    //   path.classList.add('active');
    // }
    layer.setStyle({
      fillColor: this.getStyle(layer.feature.properties, 'fill' ,true), // or RGBA format : rgba(255, 0, 0, 0.5)
      weight: 2,
      opacity: 0.5,
      color: this.getStyle(layer.feature.properties, 'border' ,true),
      dashArray: this.getStyle(layer.feature.properties, 'dashArray' ,true),
      fillOpacity: 1 // if not using RGBA
  });
  }
  private styleDefault(layer) {
    layer.options.interactive = true; // TODO - review whether we need this
    // We decided not to use CSS classes
    // const path = layer._path;
    // if (path){
    //   path.classList.remove('active');
    // }
    layer.setStyle(this.style(layer.feature)); // Reapply default style using the style function
  }
  private getStyle(properties, element: string, active:boolean){
    if(properties.selected){
      return active ? this.statusStyles.selected[element].active : this.statusStyles.selected[element].default; 
    } else if (properties.unavailable){
      return active ? this.statusStyles.unavailable[element].active : this.statusStyles.unavailable[element].default; 
    } else {
      return active ? this.statusStyles.available[element].active : this.statusStyles.available[element].default; 
    }
  }
  // private addFeatureUnavailableClass(layer) {
  //   // We decided not to use CSS classes
  //   const path = layer._path;
  //   if (path){
  //     path.classList.add('unavailable');
  //   }
  // }
  /* FOR STATISTICAL VISUALISATIONS; we'll use this later

  private getColour(d) {
    return d > 80000000 ? '#800026' :
           d > 70000000  ? '#BD0026' :
           d > 60000000  ? '#E31A1C' :
           d > 50000000  ? '#FC4E2A' :
           d > 40000000   ? '#FD8D3C' :
           d > 30000000   ? '#FEB24C' :
           d > 20000000   ? '#FED976' :
                      '#FFEDA0';
  }

  */ 

  // isCultureUnavailable(identifier: string /* iso */){
    // Do not use this approach. It trigger thousands of change detection cycles. Use the featureAvailableMap instead
  //   // console.log('iso',identifier)
  //   return this.featureLayerMap.get(identifier)?.feature?.properties?.unavailable;
  // }
  filterClusterCulturesByProperty(cluster: CultureClusterWrapper, property: string) : Culture[]{
    return cluster?.cultures?.filter(c=>this.featureLayerMap.get(c.iso)?.feature?.properties?.[property])
  }
  setFeatureSelectedStatusByIdentifier(identifier: string, selected:boolean) {
    const layer = this.featureLayerMap.get(identifier);
    // console.log('feature',layer.feature);
    if (!layer) {return;};
    layer.feature.properties.selected = !!selected;
    layer.setStyle(this.style(layer.feature)); // Reapply default style using the style function
  }
  getFeatureLayer(feature: GeoJsonFeature){
    return this.featureLayerMap.get(feature.properties.identifier);
  }
  getFeatureFromFeatureIdentifier(feature_identifier: string){
    return this.features.find(f=>f.properties.identifier == feature_identifier);
  }
  // goToCulture(culture:Culture){
  //   const mapFeature = this.getFeatureFromFeatureIdentifier(culture?.iso);
  //   if (mapFeature){
  //     const bounds = this.getFeatureLayer(mapFeature)?.getBounds();
  //     if(bounds){
  //       this.fitBounds(bounds);
  //     }
  //   }
  // }
  selectCulture(culture:Culture){
    // this.goToCulture(culture);
    this.selectedCulture.emit(culture);
  }
  includedInSelected(iso:string){
    return this.selectedCultureIsos.includes(iso);
  }
  onEachFeature(feature, layer) {
    layer.bindTooltip(feature.properties.culture?.flag?.emoji ? feature.properties.culture.flag.emoji +' ' + feature.properties.culture.name : feature.properties.culture?.name, {sticky:true}).openPopup();
    layer.on('click', (e) => {
      // 'e' is the MouseEvent object, so we could also target the layer as e.target
      if (!feature.properties.unavailable){
        this.selectCulture(feature.properties.culture);
      }
    });
    layer.on('mouseover', (e) => {
      this.styleActive(e.target);
    });
    layer.on('mouseout', (e) => {
      this.styleDefault(e.target)
      // layer.closePopup();
    });


    // if(layer.feature.properties.unavailable === true){
    //   this.addFeatureUnavailableClass(layer);
    // }
    // end of TODO

    this.featureLayerMap.set(feature.properties.identifier, layer);
    this.featureAvailableMap.set(feature.properties.identifier, feature.properties.available);
  };

  private initialiseMap() {
    const baseMapURl = 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png';
    const options = {
      attribution : '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
      maxZoom: this.maxZoom, // max zoom on scroll
      minZoom: this.minZoom,
      // center: [51.505, -0.09], // London centering - added by milos //Richard please review this I just try to set London as a center of the map
    }
    this.map = L.map('map', {
      attributionControl: false, // hide Leaflet's current campaign
      maxBounds:this.maxBounds, // we have problems when you can scroll past the international date line. This helps.
      maxBoundsViscosity: 0.5 // allow the user to elastically extend the map beyond the edge of the map (a little wraparound duplication)
    }
    );
    let attributionControl = L.control.attribution().addTo(this.map);
    attributionControl.setPrefix('<a href="https://leafletjs.com/">Leaflet</a>'); // restore Leaflet attribution
    L.tileLayer(baseMapURl, options).addTo(this.map);
    L.geoJSON(this.featureCollection, {
      style:this.style,
      onEachFeature: this.onEachFeature,
      // wrapLatLng: true // Wrap longitude coordinates across the date-line... this was not needed
    }).addTo(this.map);
    this.map.on('moveend', this.logCenterAndZoom); // for dev. TODO remove this when dev complete

    // this.map.setView([51.505, -0.09], 13); //starting zoom positon - added by milos ( I tried to center the map but failed )
    this.map.fitWorld().setZoom(2);
  }
  private featuresNearInternationalDateLine (features:GeoJsonFeature[]){
    return features ? features.filter(f=>f.properties?.displayOverDateLineInFarEast) : [];
    // The following code has potential to filter dynamically without the hardcoded 'displayOverDateLineInFarEast' property, but at the moment, the code below is capturing the wrong countries
    // return features ? features.filter(f => 
    //   (f.geometry.coordinates[0].length > 1 /*f.geometry.type == 'MultiPolygon'*/ && f.geometry.coordinates[0].map(lngLat => lngLat[0][0]).some(lng=>lng < this.limitToDisplayFarWesternFeaturesInFarEast)) ||
    //   (f.geometry.coordinates[0].length == 1 /*f.geometry.type == 'Polygon'*/ && f.geometry.coordinates.map(lngLat => lngLat[0][0][0]).some(lng=>lng < this.limitToDisplayFarWesternFeaturesInFarEast))  
    // ) : [];
  };
  private moveFeatureEastOfDateLine(feature :GeoJsonFeature) : GeoJsonFeature{
    if (feature?.geometry?.type === 'Polygon'){
      feature.geometry.coordinates = feature.geometry.coordinates.map(territory => territory.map(latLng => [
        (latLng[0] as number ) > 0 ? latLng[0] : latLng[0] +=360,latLng[1]]));
    } else if (feature.geometry.type === 'MultiPolygon'){
      feature.geometry.coordinates = feature.geometry.coordinates.map(territory => territory.map(latLngCollection => latLngCollection.map(latLng => [
        (latLng[0] as number ) > 0 ? latLng[0] : // if it is already in the eastern hemisphere, let it be there
          ((latLng[0] as number ) +=360), // otherwise move it from the western to the eastern hemisphere
          latLng[1]])));
    }
    return feature;
  }
  private mergeFeatureArrays(firstArray: GeoJsonFeature[], secondArray: GeoJsonFeature[]): GeoJsonFeature[] {
    const mergedArray = firstArray.map(feature => ({ ...feature }));
    for (const newFeature of secondArray) {
        const index = mergedArray.findIndex(feature =>
          feature.properties?.identifier === newFeature.properties?.identifier &&
          feature.properties?.className === newFeature.properties?.className
        );
        if (index !== -1) {
            mergedArray[index] = newFeature;
        } else {
            mergedArray.push(newFeature);
        }
    }

    return mergedArray;
}
  private moveFeaturesNearDateLine(features:GeoJsonFeature[]) {
    if(this.displayFarWesternFeaturesInFarEast){
      const islandsNearTheDateLine = this.featuresNearInternationalDateLine(features);
      const updatedIslandsNearTheDateLine = islandsNearTheDateLine.map(island => this.moveFeatureEastOfDateLine(island));
      features = this.mergeFeatureArrays(features,updatedIslandsNearTheDateLine);
    }
    return features;
  }
  private makeFeatureCollection(features:GeoJsonFeature[],featureCollectionName) {
    const featureCollection : GeoJsonFeatureCollection = {
      type : 'FeatureCollection',
      name : featureCollectionName,
      features : features,
    }
    return featureCollection;
  }
  // private addMarkers() {
  //   // Add your markers to the map
  //   this.markers.forEach(marker => marker.addTo(this.map));
  // }

  // private fitMarkers() {
  //   // Create a LatLngBounds object to encompass all the marker locations
  //   const bounds = L.latLngBounds(this.markers.map(marker => marker.getLatLng()));
  //   // Fit the map view to the bounds
  //   this.map.fitBounds(bounds);
  // }
  private fitBounds(bounds : L.LatLngBounds) {
    // console.log('Flying to bounds: ',bounds)
    this.map.flyToBounds(bounds);
  }
  initialiseEverything(features : GeoJsonFeature[]) {
    if(this.viewInitialised && features){
      if(this.displayFarWesternFeaturesInFarEast){
        features = this.moveFeaturesNearDateLine(features);
        this.features = features;
      }
      this.featureCollection = this.makeFeatureCollection(features,this.featureCollectionName);
      this.initialiseMap();
      // this.addMarkers();
      // this.fitMarkers();
      this.map.fitWorld().setZoom(2);
    }
  }
  selectClusterById(id:number){
    let cluster : CultureClusterWrapper;
    if (id){
      cluster = this.clusters.find(c=>c.id === +id);
    }
    this.selectCluster(cluster);
  }
  selectCluster(clusterWrapper:CultureClusterWrapper){
    this.selectedCluster = !clusterWrapper || clusterWrapper.id === this.selectedCluster?.id ? null : clusterWrapper;
    if(this.selectedCluster){
      this.selectedClusterId = this.selectedCluster.id;
      console.log('Continent: ',clusterWrapper.cluster.name);
      this.fitBounds(this.selectedCluster.cluster.geometry_meta.bounds);
      // this.panTo(this.selectedCluster.cluster.geometry_meta.center,true,this.selectedCluster.cluster.geometry_meta.zoom);
    } else {
      this.selectedClusterId = null;
      // this.fitMarkers();
      this.map.fitWorld().setZoom(1);
    }
  }

  logCenterAndZoom() { // For dev
   if(this.map){
    const center: L.LatLng = this.map.getCenter();
    const zoomLevel: number = this.map.getZoom();
    const bounds: L.LatLngBounds = this.map.getBounds();
    console.log('Center:', center);
    console.log('Zoom:', zoomLevel);
    console.log('Bounds: ',bounds);
   }
  }
  isMapView(){
    return this.view === 'map';
  }
  toggleView (){
    this.view = this.view == 'map' ? 'list' : 'map';
  }

  trackByClusterId(clusterWrapper: CultureClusterWrapper): number {
    return clusterWrapper.id; // Replace 'id' with the actual property name representing the unique identifier
  }

  getFallbackFlagUrl (){
    return this.mediaService.fallback_flag_round_url;
  }
  updateFeatureStyles(currentSelectedCultureIsos:string[],previousSelectedCultureIsos:string[]){
    let newIdentifiers = currentSelectedCultureIsos.filter(item => !previousSelectedCultureIsos.includes(item));
    let removedIdentifiers = previousSelectedCultureIsos.filter(item => !currentSelectedCultureIsos.includes(item));
    newIdentifiers.forEach(identifier=>{
      this.setFeatureSelectedStatusByIdentifier(identifier,true);
    });
    removedIdentifiers.forEach(identifier=>{
      this.setFeatureSelectedStatusByIdentifier(identifier,false);
    })
  }

  ngOnChanges(changesObject) {
    if(changesObject.features?.currentValue && changesObject.featureCollectionName?.currentValue){
      this.initialiseEverything(changesObject.features.currentValue)
    }
    if(changesObject.selectedCultureIsos?.previousValue){
      this.updateFeatureStyles(changesObject.selectedCultureIsos.currentValue,changesObject.selectedCultureIsos.previousValue)
    }
  }

  ngAfterViewInit() {
    this.viewInitialised = true;
    this.initialiseEverything(this.features);
  }

}
