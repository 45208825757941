import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { StatisticNumerical } from '@frontend/core';
import { AuthService, UserExtraLite } from '../../../auth';

@Component({
  selector: 'multisite-number-thumbnails-banner',
  templateUrl: './number-thumbnails-banner.component.html',
  styleUrls: ['./number-thumbnails-banner.component.scss']
})
export class NumberThumbnailsBannerComponent implements OnInit {

  @Input() titleTranslationKey : string; // 'ciprofile.get_access_title'
  @Input() titleText : string; // 'How to get access'
  @Input() descriptionTranslationKey : string; // 'ciprofile.get_access_description'
  @Input() descriptionText : string; // 'Our community gives access. Get in touch with a member.'
  @Input() users : UserExtraLite[]; // at least 4 please
  @Input() statistic : StatisticNumerical; // at least the t
  @Output() linkClicked = new EventEmitter();

  constructor(private authService : AuthService) { }

  doLinkClick(){
    this.linkClicked.emit();
  }

  ngOnInit(): void {
    if(!this.users?.length){
      this.users = this.authService.getDummyUsersExtraLite(4);
    }
  }

}
