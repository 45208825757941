<div class="selector-with-map">
  <div class="content-holder">
    <div class="title-group" *transloco="let t">
      <p class="title-label" *ngIf="preambleTranslationKey || preambleText">{{preambleTranslationKey ? t(preambleTranslationKey) : preambleText}}</p>
      <p class="title h3" *ngIf="titleTranslationKey || titleText">{{titleTranslationKey ? t(titleTranslationKey) : titleText}}</p>
      
      <p class="subtitle mb-4" *ngIf="descriptionTranslationKey || descriptionText">{{descriptionTranslationKey ? t(descriptionTranslationKey) : descriptionText}}</p>
    </div>
    <div class="d-flex flex-row justify-content-between flex-fit w-100 mx-auto">
      <div *ngIf="clusters?.length"
        class="d-flex flex-row align-items-center justify-content-start align-self-start flex-wrap gap-2">
        <multisite-icon-pill
          (click)="selectCluster(null)" [iconClasses]="'fa-solid fa-globe'"
          [labelTranslationKey]="'common.all'" [selectable]="true"
          [inverted]="!selectedCluster" class="d-none d-lg-flex">
        </multisite-icon-pill>
        <multisite-icon-pill *ngFor="let clusterWrapper of clusters; trackBy:trackByClusterId"
          (click)="selectCluster(clusterWrapper)" [iconClasses]="'fa-solid fa-globe'"
          [labelText]="clusterWrapper.cluster?.name" [selectable]="true"
          [inverted]="clusterWrapper.id === selectedCluster?.id" class="d-none d-lg-flex">
        </multisite-icon-pill>
        <select  class="form-select d-block d-lg-none w-100" aria-label="Select Quiz" [(ngModel)]="selectedClusterId" (change)="selectClusterById($event.target.value)">
          <option [value]="null" *transloco="let t">{{t('common.all')}}</option>
          <option *ngFor="let clusterWrapper of clusters; trackBy:trackByClusterId" [value]="clusterWrapper.id">{{ clusterWrapper.cluster?.name }}</option>
        </select>
      </div>

      <div class="form-check form-switch d-flex gap-1 flex-nowrap align-self-start" *transloco="let t">
        <input type="radio" class="btn-check" name="options" id="option1" autocomplete="off" (click)="toggleView()"
          [disabled]="isMapView()" [checked]="isMapView()">
        <label class="btn btn-secondary" for="option1"><i class="fa-solid fa-location-dot"></i>
          {{t('common.map')}}</label>

        <input type="radio" class="btn-check" name="options" id="option2" autocomplete="off" (click)="toggleView()"
          [disabled]="!isMapView()" [checked]="!isMapView()">
        <label class="btn btn-secondary" for="option2"><i class="fa-solid fa-list"></i> {{t('common.list')}}</label>

      </div>
    </div>

  </div>
  <div class="map-container" [hidden]="!isMapView()">
    <div class="map-frame">
      <div id="map"></div>
    </div>
  </div>
  <div [hidden]="isMapView()">
    

    <div class="mb-4" *ngFor="let clusterWrapper of clusters; trackBy:trackByClusterId"
      [ngClass]="{'d-none': selectedCluster && selectedCluster.id !== clusterWrapper.id}">
      <h3>{{clusterWrapper.cluster?.name}}</h3>
      <ul class="flexlist w-100" *ngIf="clusterWrapper?.cultures?.length">
        <li class="flexlist-item" *ngFor="let culture of hideUnavailableCulturesFromList ? filterClusterCulturesByProperty(clusterWrapper,'available') : clusterWrapper.cultures">
          <!-- <img src="{{cloudinary_base_url+'w_32,c_fill,ar_1:1,r_max,f_auto/flags/'+culture.flag?.hash+'.png'}}" width="24px" alt="">
          {{culture.name}} -->
          <multisite-avatar-pill
            (selected)="selectCulture(culture)"
            [removalMode]="includedInSelected(culture.iso) && !disabled && !loading"
            [disabled]="!featureAvailableMap.get(culture.iso) && !allowUnavailableCulturesToBeSelected"
            [active]="includedInSelected(culture.iso)"
            [fallbackImageUrl]="getFallbackFlagUrl()"
            [fallbackTranslationKey]="'tracking.missing'"
            [cursorNone]="disabled || loading" [clickable]="true"
            [imageUrl]="cloudinary_base_url+'w_32,c_fill,ar_1:1,r_max,f_auto/flags/round/'+culture.flag?.hash+'.png'"
            [text]="culture.name"
            [hideBackground]="true"
            [compact]="false"
            ></multisite-avatar-pill>
        </li>
      </ul>



      <!-- <div class="contributors" *ngIf="clusterWrapper?.cultures?.length">
        <span *ngFor="let culture of clusterWrapper.cultures" [ngClass]="{ disabled: false }">
          <multisite-avatar-pill (selected)="selectCulture(culture.iso)"
            [removalMode]="includedInSelected(culture.iso) && !disabled && !loading"
            [active]="includedInSelected(culture.iso)" [fallbackImageUrl]="getFallbackFlagUrl()"
            [fallbackTranslationKey]="'tracking.missing'" [cursorNone]="disabled || loading" [clickable]="true"
            [imageUrl]="cloudinary_base_url+'w_32,c_fill,ar_1:1,r_max,f_auto/flags/'+culture.flag?.hash+'.png'"
            [text]="culture.name"></multisite-avatar-pill>
        </span>
      </div> -->
    </div>

  </div>
</div>