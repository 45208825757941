<div class="access-code-card access-code-card-float">
  <div class="row" *transloco="let t">
    <div class="col-md-6">
      <p class="title">{{t('common.join_an_event')}}</p>
      <!-- <p>  
      There is a short description what the event is. Vivamus vitae ex dictum, elementum libero vel, porta odio. Donec
        sodales enim quis ipsum malesuada tempus. Mauris ultricies purus vel viverra iaculis.
      </p> -->
    </div>
    <div class="col-md-6">
      <multisite-access-code-form (submitted)="submitEventCode($event)" [error]="eventCodeError" [loading]="loading"></multisite-access-code-form>
      <!-- <div class="input-group-button w-100 my-2">
        <input type="text" [placeholder]="t('common.access_code')" [attr.aria-label]="t('common.access_code')">
        <button class="btn btn-sm btn-primary" type="button"><i class="fa-solid fa-arrow-right"></i></button>
      </div> -->
    </div>
  </div>
</div>